import React, { useContext, useEffect, useCallback, useState } from 'react';
import ArticleCard from '../articles/ArticleCard';
import HeroSection from '../layout/HeroSection';
import Tag from '../articles/Tag';
import DateFilter from '../common/DateFilter';
import { AppDataContext } from '../../context/AppDataProvider';
import './Homepage.css';
import Pagination from '../common/Pagination';
import { useSearchParams, useNavigate } from 'react-router-dom';

const Homepage = () => {
  const {
    tags,
    selectedTag,
    handleTagClick,
    nonFeaturedArticles,
    isLoading,
    error,
    loadMoreNonFeaturedArticles,
    homepageData,
    handleDateFilter,
    selectedDate,
    loadMoreFilteredResults,
    currentFilterPage,
    ITEMS_PER_PAGE,
    totalPages,
    totalItems,
  } = useContext(AppDataContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Sort and filter articles
  const filteredArticles = React.useMemo(() => {
    let articles = [...nonFeaturedArticles].sort((a, b) => {
      const dateA = new Date(a.attributes.article_DateTimeStamp);
      const dateB = new Date(b.attributes.article_DateTimeStamp);
      return dateB - dateA;
    });

    return articles;
  }, [nonFeaturedArticles]);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update the scroll handler with debouncing and better threshold checking
  useEffect(() => {
    let isLoading = false;
    const handleScroll = () => {
      if (isLoading || selectedDate || selectedTag) return;

      const scrollPosition = window.innerHeight + window.pageYOffset;
      // Trigger much earlier - when user is 2000px from bottom
      const threshold = document.documentElement.offsetHeight - 2000; 

      if (scrollPosition > threshold) {
        isLoading = true;
        loadMoreNonFeaturedArticles()
          .finally(() => {
            isLoading = false;
          });
      }
    };

    // More responsive debounce
    let scrollTimeout;
    const debouncedHandleScroll = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(handleScroll, 50); // Even faster response time
    };

    if (!selectedDate && !selectedTag) {
      window.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        window.removeEventListener('scroll', debouncedHandleScroll);
        if (scrollTimeout) {
          clearTimeout(scrollTimeout);
        }
      };
    }
  }, [selectedDate, selectedTag, loadMoreNonFeaturedArticles]);

  // Get page from URL or default to 1
  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get('page')) || 1;
    const tagFromUrl = searchParams.get('tag');
    
    if (tagFromUrl && tagFromUrl !== selectedTag) {
      handleTagClick(tagFromUrl, pageFromUrl, true);
    } else if (pageFromUrl !== currentFilterPage) {
      handlePageChange(pageFromUrl);
    }
  }, [searchParams]);

  const handlePageChange = (page) => {
    // Scroll to top immediately before loading new data
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });

    // Update URL with new page number
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', page);
    if (selectedTag) {
      newParams.set('tag', selectedTag);
    }
    setSearchParams(newParams);

    // Load the data
    if (selectedDate) {
      loadMoreFilteredResults(page);
    } else if (selectedTag) {
      handleTagClick(selectedTag, page, true);
    }
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    const currentPage = currentFilterPage || 1;

    if (totalPages === 0) return null;

    return (
      <div className="pagination-container">
        <div className="pagination-info">
          Showing page {currentPage} of {totalPages} ({totalItems} total articles)
        </div>
        <div className="pagination-controls">
          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="page-number">{currentPage}</span>
          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  if (isLoading && nonFeaturedArticles.length === 0) {
    return (
      <div className="loading-indicator">
        <p>Loading articles...</p>
      </div>
    );
  }

  return (
    <div className="homepage-container">
      {homepageData?.homepage_title && (
        <div className="homepage-title">
          <h1>{homepageData.homepage_title}</h1>
        </div>
      )}
      
      {/* Only show HeroSection if no filters are active and not on mobile */}
      {!selectedDate && !selectedTag && !isMobile && <HeroSection />}
      
      <div className="filter-section">
        <div className="tag-container">
          {tags.map((tag, index) => (
            <Tag
              key={index}
              text={tag}
              onClick={() => handleTagClick(tag)}
              isSelected={tag === selectedTag}
            />
          ))}
        </div>
        <DateFilter 
          onDateChange={handleDateFilter}
          onClearDate={() => handleDateFilter(null)}
          selectedDate={selectedDate}
        />
      </div>
      
      <section className="articles">
        {filteredArticles.length > 0 ? (
          filteredArticles.map(article => (
            <ArticleCard key={article.id} article={article} />
          ))
        ) : (
          <p>No articles found for the selected date.</p>
        )}
      </section>

      {!selectedDate && !selectedTag && !isLoading && nonFeaturedArticles.length > 0 && (
        <div className="loading-indicator">
          <p>Loading more articles...</p>
        </div>
      )}
      
      {error && (
        <div className="error-message">
          <p>Error loading articles: {error.message}</p>
        </div>
      )}

      {(selectedDate || selectedTag) && (
        <Pagination 
          currentPage={currentFilterPage || 1}
          totalPages={Math.max(1, Math.ceil(totalItems / 25))}
          onPageChange={handlePageChange}
          totalArticles={totalItems}
        />
      )}
    </div>
  );
};

export default Homepage;
