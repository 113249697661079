import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './DateFilter.css';

// Extend dayjs with UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const DateFilter = ({ onDateChange, onClearDate, selectedDate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (date) => {
    if (date) {
      // Ensure we're working with UTC dates
      const utcDate = dayjs(date).utc().startOf('day').format('YYYY-MM-DD');
      onDateChange(utcDate);
    }
    setIsOpen(false);
  };

  const formatDisplayDate = (date) => {
    if (!date) return 'Select Date';
    // Convert UTC date to local timezone for display
    return dayjs.utc(date).local().format('MMM D, YYYY');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="date-filter-container">
        <div 
          className={`date-filter-button ${selectedDate ? 'active' : ''}`}
          onClick={() => setIsOpen(true)}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setIsOpen(true);
            }
          }}
        >
          <span className="calendar-icon">📅</span>
          <span className="date-label">{formatDisplayDate(selectedDate)}</span>
          {selectedDate && (
            <span 
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                onClearDate();
              }} 
              className="clear-filter-button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.stopPropagation();
                  onClearDate();
                }
              }}
            >
              ×
            </span>
          )}
        </div>

        <MobileDatePicker
          open={isOpen}
          onClose={() => setIsOpen(false)}
          value={selectedDate ? dayjs.utc(selectedDate) : null}
          onChange={handleChange}
          slots={{
            textField: () => null
          }}
          slotProps={{
            mobilePaper: {
              sx: {
                '.MuiPickersLayout-root': {
                  bgcolor: 'white',
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                },
                '.MuiPickersToolbar-root': {
                  display: 'none',
                },
                '.MuiPickersDay-root': {
                  borderRadius: '50%',
                  '&.Mui-selected': {
                    bgcolor: '#007AFF',
                  },
                },
                '.MuiDialogActions-root': {
                  bgcolor: '#f8f8f8',
                  borderTop: '1px solid #e0e0e0',
                },
              },
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DateFilter; 