import React, { useState, useEffect, useCallback } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../common/Logo';
import NavigationMenu from '../navigation/NavigationMenu';
import './Header.css';

const Header = ({ siteData }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const { navigationItems = [], socialMediaLinks = [], websiteLogo, websiteTitle } = siteData || {};

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const controlHeader = useCallback(() => {
    const currentScrollY = window.scrollY;
    
    if (currentScrollY < 50 || currentScrollY < lastScrollY || isMenuOpen) {
      setIsHeaderVisible(true);
    } else {
      setIsHeaderVisible(false);
    }
    
    setLastScrollY(currentScrollY);
  }, [lastScrollY, isMenuOpen]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeader);
      return () => window.removeEventListener('scroll', controlHeader);
    }
  }, [controlHeader]);

  return (
    <header className={`header ${isHeaderVisible ? 'visible' : 'hidden'}`}>
      <Logo logoUrl={websiteLogo} siteName={websiteTitle} />
      <div className="hamburger-container">
        {isMenuOpen ? (
          <FaTimes className="hamburger" onClick={toggleMenu} size={30} />
        ) : (
          <FaBars className="hamburger" onClick={toggleMenu} size={30} />
        )}
      </div>
      <NavigationMenu
        items={navigationItems}
        socialMediaLinks={socialMediaLinks}
        isMenuOpen={isMenuOpen}
        onLinkClick={() => {
          setIsMenuOpen(false);
          setIsHeaderVisible(true);
        }}
        websiteLogo={websiteLogo}
      />
    </header>
  );
};

export default Header;
