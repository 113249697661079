// NavigationMenu.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import './NavigationMenu.css';

const NavigationMenu = ({ items = [], socialMediaLinks = [], isMenuOpen, onLinkClick, websiteLogo }) => {
  React.useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <>
      {/* Desktop Navigation */}
      <nav className="desktop-navigation-menu">
        {items.map((item, index) => (
          <Link 
            key={`desktop-${index}`}
            to={item.link_url} 
            className={`navigation-item ${item.is_Featured_Link ? 'navigation-item-highlighted' : ''}`}
          >
            {item.link_name}
          </Link>
        ))}
      </nav>

      {/* Mobile Menu */}
      <div className={`mobile-menu-container ${isMenuOpen ? 'nav-active' : ''}`}>
        <div className="mobile-menu-header">
          <img src={websiteLogo} alt="Site Logo" className="mobile-logo" />
          <div className="close-button" onClick={onLinkClick}>
            <FaTimes size={30} color="white" />
          </div>
        </div>
        <div className="menu-content">
          <nav className="mobile-navigation-menu">
            {items.map((item, index) => (
              <Link 
                key={`mobile-${index}`}
                to={item.link_url} 
                className={`navigation-item ${item.is_Featured_Link ? 'navigation-item-highlighted' : ''}`}
                onClick={onLinkClick}
              >
                {item.link_name}
              </Link>
            ))}
          </nav>
        </div>
        <div className="social-media-container">
          {socialMediaLinks.map((link, index) => (
            <a 
              key={index} 
              href={link.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-media-link" 
              onClick={onLinkClick}
            >
              <img 
                src={link.logo_url.startsWith('http') ? link.logo_url : `${process.env.REACT_APP_STRAPI_BASE_URL}${link.logo_url}`} 
                alt={link.platform} 
                className="social-icon" 
              />
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;
