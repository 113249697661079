import React, { useEffect, useContext } from 'react';
import { AppDataContext } from '../../context/AppDataProvider';
import AboutHeroSection from '../layout/AboutHeroSection';
import ContentSection from '../layout/ContentSection';
import './AboutPage.css';

const AboutPage = () => {
  const { aboutData, isAboutLoading, aboutError } = useContext(AppDataContext);

  useEffect(() => {
    if (aboutData) {
      // Any additional logic if needed
    }
  }, [aboutData]);

  if (isAboutLoading) {
    return <p>Loading...</p>;
  }

  if (aboutError) {
    return <div className="error-message">Error: {aboutError.message}</div>;
  }

  const blocks = aboutData?.data?.attributes?.blocks || []; // Safely access blocks, default to an empty array

  return (
    <div className="about-container">
      <header>
        {/* Add header content if needed */}
      </header>
      <main>
        {blocks.map((block, index) => {
          const imageUrl = block?.image?.[0]?.image?.data?.attributes?.url
            ? `${process.env.REACT_APP_STRAPI_BASE_URL}${block.image[0].image.data.attributes.url}`
            : ''; // Fallback to an empty string if the URL is unavailable

          const imageAlt = block?.image?.[0]?.alt || 'SWFL.IO'; // Fallback to a default alt text

          if (index === 0) {
            return (
              <AboutHeroSection
                key={block.id}
                headline={block.headline}
                htmlContent={block.text}
                imageUrl={imageUrl}
                imageAlt={imageAlt}
              />
            );
          } else {
            return (
              <ContentSection
                key={block.id}
                headline={block.headline}
                htmlContent={block.text}
              />
            );
          }
        })}
      </main>
    </div>
  );
};

export default AboutPage;
