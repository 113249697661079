// Tag.jsx
import React from 'react';
import './Tag.css';

const Tag = ({ text, onClick, isSelected }) => {
  const handleInteraction = (e) => {
    // Prevent default behavior
    e.preventDefault();
    e.stopPropagation();
    
    // Call the onClick handler
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={`tag ${isSelected ? 'selected' : ''}`}
      onClick={handleInteraction}
      onTouchStart={(e) => e.preventDefault()} // Prevent ghost clicks
      onTouchEnd={handleInteraction}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleInteraction(e);
        }
      }}
    >
      {text}
    </div>
  );
};

export default Tag;
