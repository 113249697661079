import React from 'react';
import PropTypes from 'prop-types';
import './Ad.css';

const Ad = ({ adUrl, image, altText = "Ad" }) => {
  return (
    <div className="ad-space">
      <a href={adUrl} target="_blank" rel="noopener noreferrer">
        <img src={image} alt={altText} className="ad-image" />
      </a>
    </div>
  );
};

Ad.propTypes = {
  adUrl: PropTypes.string.isRequired, // URL the ad should link to
  image: PropTypes.string.isRequired, // Image URL to display
  altText: PropTypes.string, // Alt text for accessibility (optional)
};

export default Ad;
