// Logo.jsx
import React, { useEffect, useRef } from 'react';
import './Logo.css';

const Logo = ({ logoUrl, siteName }) => {
  const logoRef = useRef(null);
  const containerRef = useRef(null);
  const linkRef = useRef(null);

  return (
    <a href="/" className="logo-link" ref={linkRef}>
      <div ref={containerRef} className="logo-container">
        {logoUrl && (
          <img 
            ref={logoRef} 
            src={logoUrl} 
            alt={siteName} 
            className="logo-image" 
            width="85"
            height="85"
            style={{
              aspectRatio: '1/1',
              maxHeight: '85px',
              maxWidth: '85px'
            }}
          />
        )}
        <span className="site-name"><strong>{siteName}</strong></span>
      </div>
    </a>
  );
};

export default Logo;
