// src/components/providers/AppDataProvider.jsx

import React, { createContext, useState, useEffect, useMemo } from 'react';
import axiosInstance from '../services/axiosConfig';
import dayjs from 'dayjs';

// Create the context
export const AppDataContext = createContext();

// Create the provider component
export const AppDataProvider = ({ children }) => {
  // =========================
  // State Management
  // =========================

  // State for tags
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);

  // State for articles
  const [allArticles, setAllArticles] = useState([]);
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [nonFeaturedArticles, setNonFeaturedArticles] = useState([]);
  const [latestArticles, setLatestArticles] = useState([]);
  const [articleDetails, setArticleDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [nonFeaturedPage, setNonFeaturedPage] = useState(1);
  const [hasMoreNonFeatured, setHasMoreNonFeatured] = useState(true);
  const [error, setError] = useState(null);

  // State for homepage data
  const [homepageData, setHomepageData] = useState(null);

  // State for site data
  const [siteData, setSiteData] = useState(null);
  const [isSiteDataLoading, setIsSiteDataLoading] = useState(true);

  // State for About Data
  const [aboutData, setAboutData] = useState(null);
  const [isAboutLoading, setIsAboutLoading] = useState(true);
  const [aboutError, setAboutError] = useState(null);

  // =========================
  // State for Ads
  // =========================
  const [ads, setAds] = useState({});
  const [isAdsLoading, setIsAdsLoading] = useState(true);
  const [adsError, setAdsError] = useState(null);

  // Add these new state declarations BEFORE your functions
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  
  // Constants should be defined at component level
  const PAGE_SIZE = 25;  // Using uppercase for constants

  // Add these to your existing state declarations
  const [selectedDate, setSelectedDate] = useState(null);

  // Add these new state declarations with the other state declarations at the top
  const [currentFilterPage, setCurrentFilterPage] = useState(1);
  const [hasMoreFilteredResults, setHasMoreFilteredResults] = useState(true);
  const ITEMS_PER_PAGE = 25;

  // Add these new state variables
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  // =========================
  // Fetch Functions
  // =========================

  // Fetch tags from articles
  const fetchTags = async () => {
    try {
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      const filterDate = oneWeekAgo.toISOString();

      const response = await axiosInstance.get(
        `/api/articles?pagination[pageSize]=100&sort=publishedAt:desc&filters[publishedAt][$gt]=${filterDate}`
      );
      
      const articleData = response.data;
      const uniqueCategories = new Set();

      articleData.data.forEach(article => {
        const category = article.attributes.article_category;
        if (category) {
          uniqueCategories.add(category);
        }
      });

      const uniqueCategoryTags = [...uniqueCategories].sort((a, b) => a.localeCompare(b));
      setTags(uniqueCategoryTags);
    } catch (error) {
      setError(error);
    }
  };

// Function to fetch ads
const fetchAds = async () => {
  setIsAdsLoading(true);
  try {
    const response = await axiosInstance.get('/api/ads?populate[Image]=*'); // Ensure Image is populated
    const adsData = response.data.data.map(ad => ({
      id: ad.id,
      title: ad.attributes?.Title || 'Untitled Ad',
      adSize: ad.attributes?.Ad_Size || 'unknown',
      adUrl: ad.attributes?.Ad_URL || '',
      image: ad.attributes?.Image?.data?.attributes?.url || '', // Extract image URL
      priority: ad.attributes?.Priority || 0,
      active: ad.attributes?.Active || false,
    }));

    // Filter active ads and group them by size
    const activeParsedAds = adsData.filter(ad => ad.active && ad.adUrl && ad.image);
    const groupedAds = activeParsedAds.reduce((acc, ad) => {
      if (!acc[ad.adSize]) acc[ad.adSize] = [];
      acc[ad.adSize].push(ad);
      return acc;
    }, {});

    setAds(groupedAds);
  } catch (error) {
    setAdsError(error);
  } finally {
    setIsAdsLoading(false);
  }
};



  // Fetch ads on mount
  useEffect(() => {
    fetchAds();
  }, []);

  // Function to generate URL for articles
  const generateUrl = (article) => {
    const date = new Date(article.attributes.article_posted_date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `/${year}/${month}/${day}/${article.attributes.article_slug}`;
  };

  // Function to fetch About Data
  const fetchAboutData = async () => {
    try {
      const response = await axiosInstance.get(`/api/aboutpage?populate[blocks][populate][image][populate]=*`);
      setAboutData(response.data);
    } catch (e) {
      setAboutError(e);
    } finally {
      setIsAboutLoading(false);
    }
  };

  // Fetch About Data
  useEffect(() => {
    fetchAboutData();
  }, []);

  // Fetch all articles including featured, latest, and non-featured using Promise.all
  const fetchAllArticles = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        '/api/articles?populate=*&sort=article_DateTimeStamp:desc'
      );
      
      console.log('Fetched articles:', {
        total: response.data.data.length,
        dateRange: {
          newest: response.data.data[0]?.attributes.article_DateTimeStamp,
          oldest: response.data.data[response.data.data.length - 1]?.attributes.article_DateTimeStamp
        }
      });

      // Define all API requests
      const allArticlesRequest = axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*`);
      const featuredArticlesRequest = axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=true`);
      const latestArticlesRequest = axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&_limit=5`);
      const nonFeaturedArticlesRequest = axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=false&pagination[page]=1&pagination[pageSize]=25`);

      // Execute all requests in parallel
      const [allData, featuredData, latestData, nonFeaturedData] = await Promise.all([
        allArticlesRequest,
        featuredArticlesRequest,
        latestArticlesRequest,
        nonFeaturedArticlesRequest
      ]);

      // Process and set all articles
      setAllArticles(allData.data.data.map(article => ({ ...article, url: generateUrl(article) })));

      // Process and set featured articles
      setFeaturedArticles(featuredData.data.data.map(article => ({ ...article, url: generateUrl(article) })));

      // Process and set latest articles
      setLatestArticles(latestData.data.data.map(article => ({ ...article, url: generateUrl(article) })));

      // Process and set non-featured articles
      setNonFeaturedArticles(nonFeaturedData.data.data.map(article => ({ ...article, url: generateUrl(article) })));
      setHasMoreNonFeatured(1 < nonFeaturedData.data.meta.pagination.pageCount);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch non-featured articles when page changes
  const fetchNonFeaturedArticles = async (page) => {
    setIsLoading(true);
    try {
      const { data: nonFeaturedData } = await axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=false&pagination[page]=${page}&pagination[pageSize]=25`);
      const newArticles = nonFeaturedData.data.map(article => ({ ...article, url: generateUrl(article) }));

      setNonFeaturedArticles(prevArticles => {
        const articlesMap = new Map(prevArticles.map(article => [article.id, article]));
        newArticles.forEach(article => articlesMap.set(article.id, article));
        return Array.from(articlesMap.values());
      });

      setHasMoreNonFeatured(page < nonFeaturedData.meta.pagination.pageCount);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch article details
  const fetchArticleDetails = async (year, month, day, title) => {
    setIsLoading(true);
    const url = `/api/articles/${year}/${month}/${day}/${title}?populate[SEO]=*&populate[article_image]=*`;
    try {
      const { data } = await axiosInstance.get(url);
      setArticleDetails(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch homepage data
  const fetchHomepageData = async () => {
    try {
      const response = await axiosInstance.get('/api/homepage');
      setHomepageData(response.data.data.attributes);
    } catch (error) {
      setError(error);
    }
  };

  // Load more non-featured articles
  const loadMoreNonFeaturedArticles = async () => {
    if (isLoading || !hasMore) return;

    try {
      setIsLoading(true);
      const nextPage = currentPage + 1;
      
      let url = `/api/articles?populate[SEO]=*&populate[article_image]=*&pagination[page]=${nextPage}&pagination[pageSize]=${PAGE_SIZE}&sort=publishedAt:desc`;
      
      if (selectedTag) {
        url += `&filters[article_category][$eq]=${selectedTag}`;
      }

      const response = await axiosInstance.get(url);
      const newArticles = response.data.data;

      if (newArticles.length === 0) {
        setHasMore(false);
      } else {
        setNonFeaturedArticles(prev => [...prev, ...newArticles]);
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error('Error loading more articles:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handler for tag click
  const handleTagClick = async (tag, page = 1, isPageChange = false) => {
    setIsLoading(true);
    try {
      // Only clear tag filter if it's not a page change
      if (selectedTag === tag && !isPageChange) {
        console.log('Clearing tag filter');
        setSelectedTag(null);
        setCurrentFilterPage(1);
        
        // If there's a date filter active, fetch by date instead of all articles
        if (selectedDate) {
          const { data: articles, meta } = await fetchArticlesByDate(selectedDate, 1);
          setNonFeaturedArticles(articles);
          setTotalPages(meta.pagination.pageCount);
          setTotalItems(meta.pagination.total);
          setHasMoreFilteredResults(meta.pagination.page < meta.pagination.pageCount);
        } else {
          await fetchAllArticles();
        }
      } else {
        console.log('Setting new tag filter or changing page:', tag, page);
        if (!isPageChange) {
          setSelectedTag(tag);
        }
        setCurrentFilterPage(page);
        
        // Construct URL with both tag and date filters if date is selected
        let url = `/api/articles?populate=*&sort=publishedAt:desc&filters[article_category][$eq]=${tag}&pagination[page]=${page}&pagination[pageSize]=${ITEMS_PER_PAGE}`;
        
        if (selectedDate) {
          const startDate = dayjs.utc(selectedDate).startOf('day').toISOString();
          const endDate = dayjs.utc(selectedDate).endOf('day').toISOString();
          url += `&filters[article_DateTimeStamp][$gte]=${startDate}&filters[article_DateTimeStamp][$lte]=${endDate}`;
        }
        
        console.log('API request URL:', url);
        const response = await axiosInstance.get(url);
        
        const articlesWithUrls = response.data.data.map(article => ({
          ...article,
          url: generateUrl(article)
        }));

        setNonFeaturedArticles(articlesWithUrls);
        
        const { page: currentPage, pageCount, total } = response.data.meta.pagination;
        setCurrentFilterPage(currentPage);
        setTotalPages(pageCount);
        setTotalItems(total);
        setHasMoreFilteredResults(currentPage < pageCount);
      }
    } catch (error) {
      console.error('Error in handleTagClick:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Filtered and sorted articles based on selectedTag
  const filteredArticles = useMemo(() => {
    let filtered = nonFeaturedArticles;
    
    // Filter by tag if selected
    if (selectedTag) {
      filtered = filtered.filter(article => 
        article.attributes.article_category === selectedTag
      );
    }
    
    // Filter by date if selected
    if (selectedDate) {
      filtered = filtered.filter(article => {
        const articleDate = new Date(article.attributes.article_DateTimeStamp);
        const filterDate = new Date(selectedDate);
        return (
          articleDate.getFullYear() === filterDate.getFullYear() &&
          articleDate.getMonth() === filterDate.getMonth() &&
          articleDate.getDate() === filterDate.getDate()
        );
      });
    }
    
    return filtered;
  }, [selectedTag, selectedDate, nonFeaturedArticles]);

  const sortedArticles = filteredArticles.sort(
    (a, b) => new Date(b.attributes.article_posted_date) - new Date(a.attributes.article_posted_date)
  );

  // Integrated site data fetching logic
  useEffect(() => {
    async function fetchSiteData() {
      setIsSiteDataLoading(true);
      try {
        const siteDataResponsePromise = axiosInstance.get('/api/site-data?populate[seo]=*&populate[social_media_links]=*&populate[website_logo]=*');
        const headerNavResponsePromise = axiosInstance.get('/api/navigations/1?populate[navigation_links]=*');
        const footerNavResponsePromise = axiosInstance.get('/api/navigations/2?populate[navigation_links]=*');

        const [siteResponse, headerNavResponse, footerNavResponse] = await Promise.all([
          siteDataResponsePromise, 
          headerNavResponsePromise, 
          footerNavResponsePromise
        ]);

        const activeHeaderNavigationItems = headerNavResponse.data.data.attributes.navigation_links.filter(item => item.isActive);
        const activeFooterNavigationItems = footerNavResponse.data.data.attributes.navigation_links.filter(item => item.isActive);

        const websiteLogoData = siteResponse.data.data.attributes.website_logo.data;
        const websiteLogoUrl = websiteLogoData && websiteLogoData.length > 0 && websiteLogoData[0].attributes.url 
          ? websiteLogoData[0].attributes.url 
          : '';

        const siteDataFormatted = {
          websiteTitle: siteResponse.data.data.attributes.website_title,
          websiteLogo: websiteLogoUrl,
          socialMediaLinks: siteResponse.data.data.attributes.social_media_links.map(link => ({
            ...link,
            logo_url: link.logo_url || ''
          })),
          seo: siteResponse.data.data.attributes.seo,
          navigationItems: activeHeaderNavigationItems,
          footerNavigationLinks: activeFooterNavigationItems.map(link => ({
            text: link.link_name,
            path: link.link_url,
            highlighted: link.is_Featured_Link,
            footerLinkColumns: link.footer_link_columns
          })),
          footerText: siteResponse.data.data.attributes.footer_text,
        };

        setSiteData(siteDataFormatted);
      } catch (error) {
        setError(error);
      } finally {
        setIsSiteDataLoading(false);
      }
    }

    fetchSiteData();
  }, []);

  // Initial data fetch and setup interval for refreshing articles
  useEffect(() => {
    fetchAllArticles();
    fetchHomepageData();
    fetchTags();

    const timerId = setInterval(fetchAllArticles, 300000); // Refresh every 5 minutes
    return () => clearInterval(timerId);
  }, []);

  // Fetch additional non-featured articles when page changes
  useEffect(() => {
    if (nonFeaturedPage > 1) {
      fetchNonFeaturedArticles(nonFeaturedPage);
    }
  }, [nonFeaturedPage]);

  // Add this function to AppDataProvider
  const fetchArticlesByDate = async (date, page = 1) => {
    try {
      // Create start and end of day in UTC
      const startDate = dayjs.utc(date).startOf('day').toISOString();
      const endDate = dayjs.utc(date).endOf('day').toISOString();
      
      const response = await axiosInstance.get(`/api/articles`, {
        params: {
          'pagination[page]': page,
          'pagination[pageSize]': ITEMS_PER_PAGE,
          'sort[0]': 'article_DateTimeStamp:desc',
          'filters[article_DateTimeStamp][$gte]': startDate,
          'filters[article_DateTimeStamp][$lte]': endDate,
        }
      });

      return {
        data: response.data.data,
        meta: response.data.meta
      };
    } catch (error) {
      console.error('Error fetching articles by date:', error);
      setError(error);
      return {
        data: [],
        meta: { pagination: { page: 1, pageSize: 25, pageCount: 0, total: 0 } }
      };
    }
  };

  // Update the handleDateFilter function
  const handleDateFilter = async (date) => {
    setIsLoading(true);
    try {
      if (date) {
        setSelectedDate(date);
        setCurrentFilterPage(1); // Reset to page 1 when filter changes
        
        const { data: articles, meta } = await fetchArticlesByDate(date, 1);
        
        // Update articles and pagination state
        setNonFeaturedArticles(articles);
        setTotalPages(meta.pagination.pageCount);
        setTotalItems(meta.pagination.total);
        setHasMoreFilteredResults(meta.pagination.page < meta.pagination.pageCount);
        
        console.log('Date filter applied:', {
          date,
          articlesFound: articles.length,
          totalPages: meta.pagination.pageCount,
          totalItems: meta.pagination.total
        });
      } else {
        // Clear filter and reset to initial state
        setSelectedDate(null);
        setCurrentFilterPage(1);
        setNonFeaturedArticles([]);
        await fetchAllArticles();
      }
    } catch (error) {
      console.error('Error in handleDateFilter:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update loadMoreFilteredResults to handle pagination properly
  const loadMoreFilteredResults = async () => {
    if (!isLoading && hasMoreFilteredResults) {
      setIsLoading(true);
      try {
        const nextPage = currentFilterPage + 1;
        
        if (selectedDate) {
          const { data: newArticles, meta } = await fetchArticlesByDate(selectedDate, nextPage);
          
          setNonFeaturedArticles(prev => [...prev, ...newArticles]);
          setCurrentFilterPage(nextPage);
          setHasMoreFilteredResults(nextPage < meta.pagination.pageCount);
        }
      } catch (error) {
        console.error('Error loading more filtered results:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // =========================
  // Memoize the Context Value
  // =========================
  const contextValue = useMemo(() => ({
    // Tags
    tags,
    selectedTag,
    handleTagClick,

    // Articles
    allArticles,
    featuredArticles,
    nonFeaturedArticles,
    latestArticles,
    articleDetails,
    isLoading,
    loadMoreNonFeaturedArticles,
    hasMoreNonFeatured,
    fetchArticleDetails,

    // Homepage
    homepageData,

    // Site Data
    siteData,
    isSiteDataLoading,

    // About Data
    aboutData,
    isAboutLoading,
    aboutError,

    // Ads
    ads, // Grouped by size
    isAdsLoading,
    adsError,

    // Date Filter
    handleDateFilter,
    selectedDate,

    // Filtered Results
    hasMoreFilteredResults,
    loadMoreFilteredResults,
    currentFilterPage,
    ITEMS_PER_PAGE,
    totalPages,
    totalItems,
  }), [
    tags,
    selectedTag,
    handleTagClick,
    allArticles,
    featuredArticles,
    nonFeaturedArticles,
    latestArticles,
    articleDetails,
    isLoading,
    hasMoreNonFeatured,
    homepageData,
    siteData,
    isSiteDataLoading,
    aboutData,
    isAboutLoading,
    aboutError,
    ads,
    isAdsLoading,
    adsError,
    handleDateFilter,
    selectedDate,
    hasMoreFilteredResults,
    loadMoreFilteredResults,
    currentFilterPage,
    ITEMS_PER_PAGE,
    totalPages,
    totalItems,
  ]);

  // =========================
  // Provide the Context
  // =========================
  return (
    <AppDataContext.Provider value={contextValue}>
      {children}
    </AppDataContext.Provider>
  );
};
