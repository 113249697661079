import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AppDataContext } from '../../context/AppDataProvider';
import Ad from '../Ad/Ad'; // Import the updated Ad component
import './ArticleDetails.css';

const ArticleDetails = () => {
  const { year, month, day, slug } = useParams();
  const {
    fetchArticleDetails,
    articleDetails,
    isLoading,
    error,
    ads,
    isAdsLoading,
  } = useContext(AppDataContext);

  const fetchArticleDetailsRef = useRef(fetchArticleDetails);

  useEffect(() => {
    if (year && month && day && slug) {
      fetchArticleDetailsRef.current(year, month, day, slug);
      window.scrollTo(0, 0);
    }
  }, [year, month, day, slug]);


  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  if (!articleDetails || !articleDetails.data || !articleDetails.data.attributes) {
    return <div className="notFound">Article not found.</div>;
  }

  const { article_title, article_author, article_content, article_posted_date, article_source_url } =
    articleDetails.data.attributes;

  const formattedDate = article_posted_date
    ? new Date(article_posted_date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : 'Unknown Date';

  // Function to sanitize and remove unwanted content
  const sanitizeContent = (content) => {
    if (!content) return content;

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const unwantedLinks = doc.body.querySelectorAll('a[href*="qrco.de/wbbhapp"]');
    unwantedLinks.forEach((link) => {
      const parent = link.closest('p');
      if (parent) {
        parent.remove();
      }
    });

    return doc.body.innerHTML;
  };

  const sanitizedContent = sanitizeContent(article_content);

  const createMarkup = (html) => {
    return { __html: html };
  };

  // Function to get the first ad of a specific size
  const getAdBySize = (size) => {
    const ad = ads[size]?.[0];
    return ad;
  };

  // Fetch ads for different positions
  const topAd = getAdBySize('728x90');
  const inArticleAd = getAdBySize('300x250');
  const footerAd = getAdBySize('728x90');

  return (
    <div className="articleContainer">
      <h1 className="articleTitle">{article_title}</h1>
      <p className="articleMeta">
        Posted on: {formattedDate} | By: {article_author || 'Unknown'}
      </p>

      {/* Top Banner Ad */}
      <div className="ad-space ad-top">
        {isAdsLoading ? null : topAd ? (
          <Ad adUrl={topAd.adUrl} image={topAd.image} altText={topAd.title} />
        ) : null}
      </div>

      <img
        src={
          articleDetails.data.attributes.article_scraped_image_url ||
          'path/to/default/image.jpg'
        }
        alt={article_title || 'Article Image'}
        className="articleImage"
        loading="lazy"
      />
      <div
        className="articleContent"
        dangerouslySetInnerHTML={createMarkup(sanitizedContent)}
      />

      {/* In-Article Ad */}
      <div className="ad-space ad-in-article">
        {isAdsLoading ? null : inArticleAd ? (
          <Ad adUrl={inArticleAd.adUrl} image={inArticleAd.image} altText={inArticleAd.title} />
        ) : null}
      </div>

      {article_source_url && (
        <p className="articleSource">
          To view the original article,{' '}
          <a href={article_source_url} target="_blank" rel="noopener noreferrer">
            click here
          </a>.
        </p>
      )}

      {/* Footer Ad */}
      <div className="ad-space ad-footer">
        {isAdsLoading ? null : footerAd ? (
          <Ad adUrl={footerAd.adUrl} image={footerAd.image} altText={footerAd.title} />
        ) : null}
      </div>
    </div>
  );
};

export default ArticleDetails;
